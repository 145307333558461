import Vue from 'vue'
import App from './App.vue'
import './assets/global.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import router from '@/router/index.js'
import Nav from '@/globalComponents/Nav'
import Banner from '@/globalComponents/Banner'
import '@/assets/icon/iconfont.css'
import SearchInput from '@/globalComponents/SearchInput'
import OrderAndUserList from '@/globalComponents/OrderAndUserList'
import PageNation from '@/globalComponents/Pager'
import './utils/rem.js';
import './utils/vants.js'
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
 
// highlightjs
import hljs from 'highlight.js';

VMdPreview.use(githubTheme, {
  Hljs: hljs,
});

Vue.component('PageNation', PageNation)
Vue.component('SearchInput', SearchInput)
Vue.component('OrderAndUserList', OrderAndUserList)
Vue.component("Nav", Nav)
Vue.component("Banner", Banner)
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VMdPreview)
router.beforeEach((to, from, next) => {
  let userInfo = JSON.parse(localStorage.getItem('aiuserInfo'))
  let token = localStorage.getItem('aitoken');
  console.log(to,from,'dddf')
  if (!token && to.path!== '/login' && to.path!== '/downFile'&& to.path!== '/privacy') {
    console.log(token,'dddfdf')
    next('/login')
  } else {
      next();
    }
});
new Vue({
  router,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this
  }
}).$mount('#app')
