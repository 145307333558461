<template>
  <div>
    <div class="pager">
      <div class="pagerLayout">
        <div class="pageNum">
          <div>
            <el-button icon="el-icon-arrow-left"></el-button>
          </div>
        </div>
        <div class="pageNum">
          <div>共3页</div>
        </div>
        <div class="pageNum">每页100条</div>
        <div class="pageNum">
          <div class="pageNumSon">跳转到</div>
          <div class="pageNumSon">
            <div>
              <input type="number" v-model="pageNumber" />
            </div>
          </div>
          <div class="pageNumSon">页</div>
        </div>
        <div class="pageNum">
          <div>
            <el-button>跳转</el-button>
          </div>
        </div>
        <div class="pageNum">
          <div>
            <el-button icon="el-icon-arrow-right"></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "pager",
  data() {
    return {
        pageNumber:''
    };
  }
};
</script>
<style lang="scss" scoped>
.pager {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6vh;
  background-color: #fefefe;
  .pagerLayout {
    width: 92%;
    height: 100%;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 3vh;
    .pageNum:nth-child(1) {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        width: 60%;
        height: 3vh;
        margin-right: 3vw;
        .el-button {
          padding: 0px;
          font-size: 16px;
        }
      }
    }
    .pageNum:nth-child(2) {
      flex: 1.5;
    }
    .pageNum:nth-child(3) {
      flex: 2;
    }
    .pageNum:nth-child(4) {
      flex: 3;
      display: flex;
      align-items: center;
      justify-content: center;
      .pageNumSon:nth-child(1) {
        flex: 2;
      }
      .pageNumSon:nth-child(2) {
        flex: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        div {
          width: 70%;
          height: 4vh;
          input {
            width: 80%;
            height: 3vh;
            border: 1px solid #e5e5e5;
            text-align: center;
          }
        }
      }
      .pageNumSon:nth-child(3) {
        flex: 1;
      }
    }
    .pageNum:nth-child(5) {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        width: 80%;
        height: 4vh;
        .el-button {
          padding: 0px;
          background-color: #e5af5f;
          color: #fefefe;
          height: 3.5vh;
          width: 140%;
          margin-right: 1vw;
        }
      }
    }
    .pageNum:nth-child(6) {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        width: 60%;
        height: 3vh;
        margin-left: 3vw;
        .el-button {
          padding: 0px;
          font-size: 16px;
        }
      }
    }
  }
}
</style>