<template>
  <div id="app">
    <router-view  v-if="!$route.meta.keepAlive"></router-view>
    <KeepAlive>
      <router-view  v-if="$route.meta.keepAlive"></router-view>
    </KeepAlive>
    <Banner v-show="ifShowBanner"></Banner>
    <div style="display: none;">1.0.1</div>
  </div>
</template>

<script>
import { getAppCode } from "@/api/index.js";
import { setToken, getToken } from '@/utils/auth'
export default {
  name: "app",
  data() {
    return {
      ifShowBanner: true,
      code: '',
      viewParam: {}
    }
  },
  mounted() {
    localStorage.setItem('iosUrl', window.location.href)
    this.modelUtilInit()
    this.$bus.$on('ifshowBanner', (condition) => {
      this.ifShowBanner = condition
    })
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {

      }

    }
  },
  methods: {
    modelUtilInit() {
        var UA = navigator.userAgent,
            isAndroid = /android|adr/gi.test(UA),
            isIOS = /iphone|ipod|ipad/gi.test(UA) && !isAndroid,
            isBlackBerry = /BlackBerry/i.test(UA),
            isWindowPhone = /IEMobile/i.test(UA),
            isMobile = isAndroid || isIOS || isBlackBerry || isWindowPhone;
        this.viewParam = {
            isAndroid: isAndroid,
            isIOS: isIOS,
            isMobile: isMobile,
            isWeixin: /MicroMessenger/gi.test(UA),
            isQQ: /QQ/gi.test(UA)
        };
        localStorage.setItem('viewParam',JSON.stringify(this.viewParam))
        console.log(this.viewParam);
    },
  },
};
</script>

<style>
.full-screen-skeleton {
  min-height: 100vh; /* 使骨架屏充满整个屏幕 */
}
@supports (bottom: env(safe-area-inset-bottom)) {
  body {
    padding-bottom: env(safe-area-inset-bottom);
    bottom: env(safe-area-inset-bottom);
  }
}

body,
html {
  overflow: auto;
  -webkit-overflow-scrolling: auto;
}

#app {
  width: 100vw;
  height: auto;
}
</style>
