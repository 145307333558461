import request from '@/utils/axios';

// 登录修改手机号操作
export const login = (row,val) => {
    return request({
        url: '/blade-auth/token',
        method: 'post',
        headers: {
                'Captcha-Key': val.key,
                'Captcha-Code': val.code,
            },
    params: {
        grantType: 'captcha',
        // tenantId,
        account: row.account,
        password: row.password,
        type: 'account'
        }
    })
}

// 微信授权获取code
export const getAppCode = (row) => {
    return request({
        url: '/blade-auth/token',
        method: 'post',
        params: row
    })
}

// 获取验证码
export const getCaptcha = () => {
    return request({
        url: '/blade-auth/captcha',
        method: 'get',
    })
}


// 文件上传
export const putFile = (row) => {
    return request({
      url: '/blade-resource/oss/endpoint/put-file',
      method: 'post',
      data: row
    })
  }

  export const putFiles = (row) => {
    return request({
      url: '/blade-resource/oss/endpoint/uploading',
      method: 'post',
      data: row
    })
  }

  export const getFile = (row) => {
    return request({
      url: '/blade-resource/oss/endpoint/download',
      method: 'post',
      responseType:'blob',
      data: row
    })
  }
 

  // 发布
  export const informationAdd = (row) => {
    return request({
      url: '/informationcollection/submit',
      method: 'post',
      data: row
    })
  }

  // 发布列表
  export const informationList = (params) => {
    return request({
        url: '/informationcollection/list',
        method: 'get',
        params: {
            ...params,
        }
    })
}

//文心一言
export const getAi = (data) => {
    return request({
        url: '/ai/ernieBotQuery',
        method: 'post',
        data
    })
}

// ai收藏

export const submitAi = (data) => {
  return request({
      url: '/ai/collect/submit',
      method: 'post',
      data
  })
}
export const getAiList = (params) => {
  return request({
      url: '/ai/collect/list',
      method: 'get',
      params: {
        ...params,
    }
  })
}

export const deleteAi = (data) => {
  return request({
      url: '/ai/collect/remove',
      method: 'post',
      data
  })
}