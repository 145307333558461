<template>
  <div>
    <el-input placeholder="请输入粉丝信息查询" class="inputSZ">
      <el-button slot="append" icon="el-icon-search"></el-button>
    </el-input>
  </div>
</template>
<script>
export default {
  name: "searchInput",
  data() {
    return {};
  }
};
</script>
<style lang="scss" scoped>
.inputSZ::v-deep input {
  border-right: 0px;
}
</style>