<template>
  <div>
    <div class="fanList">
      <div class="fanListSonOne">
        <div class="fanListSonOneSon" v-if="waitInfoReturn">
          <div class="divSon">
            <div style="margin-left:4vw">{{ componentInfo.name }}</div>
            <div style="color:#1c68f5;font-size:.75rem;margin-left:2vw;margin-top:.2rem">左滑操作, 右滑联系</div>
          </div>
          <div class="divSon" v-if="componentInfo.ifHide">
            <el-button @click="addNewFans(componentInfo.type)">{{ componentInfo.buttonName }}</el-button>
          </div>
        </div>
        <div class="fanListSonOneSon">
          <div style="color:#b7b7ba">
            <span v-for="(item,index) in componentInfo.thisTypesList" :index="index">
              {{ item.prevName }}
              <span style="color:#cbb593">{{ item.number }}</span>
              {{ item.lastName }}
            </span>
          </div>
        </div>
        <div class="fanListSonOneSon" v-if="componentInfo.ifShowBottomInfo">
          <div class="top" v-for="(item,index) in componentInfo.actionListSend" :index="index">
            <div
              :class="item.ifTrue?getRed:nomal"
              @click="changeColorAndDrawMessage(index)"
            >{{ item.text}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OrderAndUserList",
  props:['sendWhichHttpRequest'],
  data() {
    return {
        // 这个元素就是复用的时候传送进来渲染的
      getRed: "getRed",
      nomal: "nomal",
      componentInfo:'',
      waitInfoReturn:false
    };
  },
  mounted(){
    // 模拟,需要先将返回信息处理好赋值给componentInfo,然后再将这个组件显示渲染
    if(this.sendWhichHttpRequest=='1'){
      this.componentInfo={
        name:'粉丝分类列表',
        type:1,
        buttonName:'添加新粉丝',
        ifHide:false,
        thisTypesList:[
          {
            prevName:'粉丝',
            number:21,
            lastName:'个'
          },
          {
            prevName:',淘宝号',
            number:21,
            lastName:'个'
          },
          {
            prevName:',京东号',
            number:0,
            lastName:'个'
          },
          {
            prevName:',拼多多号',
            number:0,
            lastName:'个'
          }
        ],
        actionListSend: [
        {
          ifTrue: false,
          text: '正常'
        },
        {
          ifTrue: false,
          text: '待审'
        },
        {
          ifTrue: false,
          text: '驳回'
        },
        {
          ifTrue: false,
          text: '冻结'
        },
        {
          ifTrue: false,
          text: '隐藏'
        },
        {
          ifTrue: false,
          text: '活动'
        }
      ],
        ifShowBottomInfo:true
      }
    }else if(this.sendWhichHttpRequest=='2'){
      this.componentInfo={
        name:'粉丝预约列表',
        type:2,
        buttonName:'全部预约',
        ifHide:true,
        thisTypesList:[
          {
            prevName:'可预约',
            number:0,
            lastName:'个'
          },
          {
            prevName:',已预约',
            number:0,
            lastName:'个'
          },
          {
            prevName:',未预约',
            number:0,
            lastName:'个,'
          }
        ],
        actionListSend: null,
        ifShowBottomInfo:false
      }
    }else if(this.sendWhichHttpRequest=='3'){
      this.componentInfo={
        name:'任务订单列表',
        type:1,
        buttonName:null,
        ifHide:false,
        thisTypesList:[
          {
            prevName:'今日合计',
            number:21,
            lastName:'条'
          },
          {
            prevName:' 已提交',
            number:21,
            lastName:'条'
          },
          {
            prevName:' 未完成',
            number:0,
            lastName:'条'
          },
          {
            prevName:'| 查询结果',
            number:21,
            lastName:'条'
          }
        ],
        actionListSend: [
        {
          ifTrue: false,
          text: "全部"
        },
        {
          ifTrue: false,
          text: "已完成"
        },
        {
          ifTrue: false,
          text: "进行中"
        },
        {
          ifTrue: false,
          text: "未提交"
        }
      ],
        ifShowBottomInfo:true
      }
    }
    this.waitInfoReturn=true;
  },
  methods: {
    changeColorAndDrawMessage(num) {
      for (let i = 0; i < this.componentInfo.actionListSend.length; i++) {
        this.componentInfo.actionListSend[i].ifTrue = false;
      }
      this.componentInfo.actionListSend[num].ifTrue = true;
    },
    addNewFans(type){
      switch(type){
        case 1: this.$router.push({path:'/addFans'}); break;
        case 2: alert('已全部预约'); break;
      }
      
    }
  }
};
</script>
<style lang="scss" scoped>
.fanList {
  margin-top: 3vh;
  height: auto;
  width: 100%;
  background-color: #fefefe;
  .fanListSonOne {
    height: auto;
    width: 100%;
    box-shadow: 0rem 0rem 0rem 0rem;
    .fanListSonOneSon:nth-child(1) {
      width: 100%;
      height: 6vh;
      display: flex;
      .divSon:nth-child(1) {
        flex: 2;
        display: flex;
        align-items: center;
      }
      .divSon:nth-child(2) {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        .el-button {
          width: 70%;
          height: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .fanListSonOneSon:nth-child(2) {
      border-top: 0.0625rem solid #efefef;
      width: 100%;
      height: 6vh;
      display: flex;
      align-items: center;
      div {
        font-size: 0.8125rem;
        margin-left: 4vw;
      }
    }
    .fanListSonOneSon:nth-child(3) {
      border-top: 0.0625rem solid #efefef;
      width: 100%;
      height: 6vh;
      display: flex;
      align-items: center;
      background-color: #f7f7f7;
      .top {
        flex: 1;
        text-align: center;
        color: #2e2e2e;
        font-size: 14px;
        height: 5vh;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.getRed {
  text-align: center;
  color: red;
  font-size: 14px;
  border-bottom: 2px solid red;
  width: 12vw;
  height: 4vh;
}
.nomal {
  text-align: center;
  color: #2e2e2e;
  font-size: 14px;
  height: 4vh;
  width: 12vw;
}
</style>