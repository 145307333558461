<template>
  <div class="top">
    <span class="topChildren">{{ headerNavName }}</span>
    <div class="like"></div>
  </div>
</template>
<script>
export default {
  name: "Nav",
  data(){
    return {
      headerNavName:''
    }
  },
  mounted(){
    this.$bus.$on('getHeaderName',(headerName)=>{
      this.headerNavName=headerName
    })
  }
};
</script>
<style lang="scss" scoped>
.top {
  width: 100vw;
  height: 5.58vh;
  background-color: rgb(247, 247, 247);
  box-shadow: 0rem 0.0625rem 0.375rem -0.25rem;
  text-align: center;
  line-height: 5.58vh;
  font-weight: 600;
  font-size: 1.25rem;
  position: fixed;
  top: 0;
  z-index: 100000;
  .topChildren {
    color: rgb(111, 111, 111);
  }
}
</style>